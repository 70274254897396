import { ProjectColors } from '../../../themes/mainTheme';
import { BlocksDirection } from '../../../helpers/player';
import { zIndexes } from '../../../constants/components';

const borderStyle = `1px solid ${ProjectColors.BLACK}`;
const defaultSx = { p: 0.2, border: borderStyle };

export const initSx = (cardDimension: number, blocksDirection: BlocksDirection) => {
  const defaultHandSx = {
    ...defaultSx,
    width: '100%',
  };
  const arsenalSx = { width: cardDimension * 2.2 };
  const roleSx = { my: 0.2, display: 'flex', justifyContent: 'center', cursor: 'pointer' };
  const essenceBarSx = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '30%',
  };
  const capsuleBoxSx = { height: cardDimension * 0.23 };
  const radianceBoxPositionedSx =
    blocksDirection === BlocksDirection.RIGHT_TO_LEFT ? {} : { left: cardDimension * 3.4 + 0.7 };
  const radiancePositionedSx = blocksDirection === BlocksDirection.RIGHT_TO_LEFT ? { left: -cardDimension - 0.6 } : {};

  return {
    wrapperBox: { position: 'relative' },
    outerBox: { display: 'flex' },
    radianceBox: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      position: 'absolute',
      ...radianceBoxPositionedSx,
    },
    radiance: {
      p: 0.2,
      cursor: 'pointer',
      position: 'relative',
      zIndex: zIndexes.two,
      ...radiancePositionedSx,
    },
    innerBoxPlayer: {
      width: 'fit-content',
      p: 0.5,
    },
    gridBox: {
      display: 'flex',
      my: 0.5,
      justifyContent: 'space-between',
      width: '100%',
    },
    hand: {
      withinLimit: defaultHandSx,
      overLimit: { ...defaultHandSx, backgroundColor: `${ProjectColors.PINK}` },
    },
    role: { ...roleSx, mx: 0.5 },
    arsenalOuterBlock: {
      ...arsenalSx,
      px: 0.4,
      mx: 0.2,
      borderLeft: borderStyle,
      borderRight: borderStyle,
      zIndex: 1,
    },
    nameCompact: { mb: 0.2, ...capsuleBoxSx },
    essenceBarCompact: { ...essenceBarSx, ...capsuleBoxSx, width: '100%' },
    compactPlayerInfoBox: {
      width: '35%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      maxWidth: cardDimension * 1.1,
      zIndex: 1,
    },
  } as const;
};
