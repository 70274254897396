import { PlayingCard } from '../../features/card/cardTypes';
import { Location } from '../../features/game/locationTrackerContextProvider';
import { HEADER_HEIGHT } from '../../global/app/header/header.styles';

export type Props = {
  stops: Location[];
  cards: PlayingCard[];
  runAfter?: () => void;
  runWith?: () => void;
};

export type OneMove = {
  initial: Location;
  final: Location;
};

export const durationConfig = {
  minInSec: 1.25,
  maxInSec: 2.75,
  stopsForMin: 2,
  stopsForMax: 7,
} as const;

export const fixYCoordinate = (coordinate: number, isMobile: boolean): number =>
  isMobile ? coordinate : coordinate > HEADER_HEIGHT ? coordinate - HEADER_HEIGHT : coordinate;
